<template>
  <v-card>
    <v-card-title class="text-h5" primary-title>
      <h6 class="text-h6">
        {{ $trans("wb_add_rgdpr_field_element") }}
      </h6>
    </v-card-title>
    <v-divider />
    <v-form ref="form" @submit.prevent="handleSave">
      <v-card>
        <v-list>
          <v-list-item>
            <v-text-field
              v-model="name"
              dense
              :rules="[rules.required, rules.maxChars(255)]"
              outlined
              hide-details
              :label="$trans('wb_gdpr_field_name')"
            />
          </v-list-item>
        </v-list>
        <v-list>
          <v-list-item>
            <v-list-item-title>
              {{ $trans("wb_dummy_gdpr_field_name") }}
            </v-list-item-title>
          </v-list-item>
          <v-list-item>
            <tip-tap
              v-model="label"
              :placeholder="$trans('wb_dummy_gdpr_field')"
              @input="label = $event"
            />
          </v-list-item>
        </v-list>
        <v-list class="pb-0 mb-0">
          <v-list-item>
            <v-checkbox
              v-model="required"
              :label="$trans('wb_dynamic_form_should_require')"
            />
          </v-list-item>
        </v-list>
        <v-divider />
        <v-list>
          <v-list-item>
            <v-spacer />
            <v-btn color="light" text @click="$emit('close')">
              {{ $trans("cancel") }}
            </v-btn>
            <v-btn color="primary" text type="submit">
              <span v-if="initialField">{{ $trans("save") }}</span>
              <span v-else>{{ $trans("add") }}</span>
            </v-btn>
          </v-list-item>
        </v-list>
      </v-card>
    </v-form>
  </v-card>
</template>

<script>
import {
  required,
  maxChars,
} from "@/lib/calendesk-js-library/forms/validators";
import TipTap from "@/lib/calendesk-js-library/components/TipTap";
import sharedActions from "@/lib/calendesk-js-library/mixins/sharedActions";

export default {
  name: "GdprFieldDialog",
  components: { TipTap },
  mixins: [sharedActions],
  data() {
    return {
      initialField: null,
      name: null,
      label: null,
      required: false,
      key: null,
      rules: {
        required,
        maxChars,
      },
    };
  },
  created() {
    if (this.getConfirmDialog.data && this.getConfirmDialog.data.field) {
      this.initialField = this.getConfirmDialog.data.field;

      this.name = this.initialField.name;
      this.label = this.initialField.label;
      this.required = this.initialField.required;
      this.key = this.initialField.key;
    } else {
      this.name = this.$trans("wb_dummy_gdpr_field_name");
      this.label = this.$trans("wb_dummy_gdpr_field");
      this.key = Math.floor(Math.random() * 100000000);
    }
  },
  methods: {
    handleSave() {
      if (this.$refs.form.validate()) {
        this.save();
      }
    },
    async save() {
      this.closeConfirmDialog({
        field: {
          name: this.name,
          label: this.label ? this.label.substring(0, 65000) : "",
          required: this.required,
          key: this.key,
        },
      });
    },
  },
};
</script>
